import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import postlist from '../albums.json';

class Music extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roster: [],
    };
    
  }

  componentDidMount() {
    const roster =  [...postlist];
    roster.sort((a, b) => a.order - b.order);
    this.setState({ roster });
  }

  render() {
    const { roster } = this.state;
    return (
      <Row className="content">
        {roster.map((post) => {
          let imageTag = (
            <Image
              className="album-image"
              src={post.thumbnail}
              title={post.title}
              alt={post.altTag}
            />
          );
          if (post.link) {
            imageTag = (
              <a href={post.body}>
                {imageTag}
              </a>
            );
          }
          return (
            <Col key={post.title}>
              {imageTag}
              {/* <p>{post.caption}</p> */}
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default Music;
