import React, { Component } from 'react';
import { Col, Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import logo from '../assets/images/logo-spinning.gif';

class Home extends Component {
  render() {
    return (
      <div className="content">
        <Row>
          <Col>
            <Image src={logo} className="album" />
          </Col>
        </Row>
        <Row>
          <Col xs={0} />
          <Col xl={3} lg={4} md={5} xs={12}>
            <Form
              action={'https://charlieheat.us7.list-manage.com/subscribe/post?u=8823f548326aa28d929d4c8ae&amp;id=2eced8988f'}
              method="POST"
            >
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="email"
                  id="mce-EMAIL"
                  name="EMAIL"
                  placeholder="Enter your email"
                  required
                />
              </Form.Group>
              <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden>
                <input
                  type="text"
                  name="b_8823f548326aa28d929d4c8ae_2eced8988f"
                  tabIndex="-1"
                  value=""
                />
              </div>
              <Button variant="link" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
          <Col xs={0} />
        </Row>
      </div>
    );
  }
}

export default Home;
