import React, { Component } from 'react';
import {
  Col, Button, Form, Row, FormControl,
} from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import Alert from 'react-bootstrap/Alert';

const axios = require('axios');

class Submissions extends Component {
  emptyForm = {
    pot: {
      valid: true,
      value: ''
    },
    fname: {
      valid: false,
      value: '',
    },
    lname: {
      valid: false,
      value: '',
    },
    email: {
      valid: false,
      value: '',
    },
    file: {
      valid: false,
      value: null,
    },
  }

  constructor(props) {
    super(props);
    this.state = {
      form: {
        pot: {
          valid: true,
          value: ''
        },
        fname: {
          valid: false,
          value: '',
        },
        lname: {
          valid: false,
          value: '',
        },
        email: {
          valid: false,
          value: '',
        },
        file: {
          valid: false,
          value: null,
        },
      },
      fileError: false,
      error: false,
      success: false,
      loading: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.recaptchaRef = React.createRef();
  }


  async getSignedUrls(filename, mimetype) {
    const config = {
      method: 'post',
      url: '/.netlify/functions/getUrl',
      data: {clientFilename: filename, mimeType: mimetype},
    };
    return axios(config)
  }

  sendFile(file, url) {
    var promise = new Promise(
			( resolve, reject ) => {
				var xhr = new XMLHttpRequest();
				xhr.upload.onload = ( event ) => {
					resolve();
				};
 
				xhr.upload.onerror = ( event ) => {
					reject();
				};
				xhr.upload.onabort = xhr.upload.onerror;
				xhr.upload.ontimeout = xhr.upload.onerror;
 
				xhr.open( "PUT", url );
				xhr.setRequestHeader( "Content-Type", file.type );
				xhr.send( file );
			}
		);
 
		return( promise );
  }

  async submitForm(e) {
    e.preventDefault();
    this.setState({ error: false, loading: true });
    if (!this.recaptchaRef.current.getValue()) {
      return this.setState({ error: 'Please complete the ReCAPTCHA' });
    }
    const file = this.state.form.file.value
    var { data } = await this.getSignedUrls(file.name, file.type)
    if (!data.putUrl) {
      return this.setState({ error: 'Failed to get upload url for file!', loading: false });
    }
    await this.sendFile( file, data.putUrl );
    const formData = this.state.form
    const submissionData = {
      firstName: formData.fname.value,
      lastName: formData.lname.value,
      email: formData.email.value,
      fileUrl: data.getUrl
    }

    const config = {
      method: 'post',
      url: '/.netlify/functions/submission',
      headers: {
        'Content-Type': 'application/json',
      },
      data: submissionData,
    };
    axios(config)
      .then((response) => {
        if (response.data.sent) {
          this.setState({ success: 'Thank You! Your submission has be successfully sent.', form: this.emptyForm, loading: false });
        }
      })
      .catch(function (error) {
        console.error(error)
        this.setState({ error: 'Could not send message! Please try again later.', loading: false });
      });

  }

  onChange(e) {
    this.setState((prevState) => {
      const form = { ...prevState.form };
      if (!form.hasOwnProperty(e.target.name)) {
        form[e.target.name] = {
          valid: false,
          value: null,
        };
      }
      switch (e.target.name) {
        case 'email':
          form.email.valid = this.validateEmail(e);
          break;
        default:
          form[e.target.name].valid = e.target.value.length > 0;
      }
      form[e.target.name].value = e.target.value;
      return { form };
    });
  }

  onFileChange(e) {
    const file = e.target.files[0];
    if (file.type !== 'audio/mpeg') {
      return this.setState({ fileError: 'Please upload audio files only!', file: null });
    }
    const formState = { ...this.state.form };
    console.log('file', file)
    formState.file.value = file;
    this.setState({ form: formState });
  }

  validateEmail(e) {
    const emailPattern = new RegExp('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$');
    return emailPattern.test(e.target.value);
  }

  render() {
    return (
      <Row className="content">
        <Col md={3} />
        <Col xs={12} md={6}>
          <Form onSubmit={this.submitForm} data-netlify="true" data-netlify-recaptcha="true">
            <Form.Group className="hidden">
              <Form.Label htmlFor='name'>Hello There!:</Form.Label>
              <Form.Control type='text' 
                name='pot'
                id='pot'
                onChange={this.onChange}
                value={this.state.form.pot.value} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Your Name:</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    name="fname"
                    type="text"
                    placeholder="First Name"
                    onChange={this.onChange}
                    value={this.state.form.fname.value}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    inline
                    name="lname"
                    type="text"
                    placeholder="Last Name"
                    onChange={this.onChange}
                    value={this.state.form.lname.value}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter email"
                onChange={this.onChange}
                value={this.state.form.email.value}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.File id="exampleFormControlFile1" label={this.state.form.file.value ? (this.state.form.file.value.name.length > 30 ? `${this.state.form.file.value.name.slice(0, 30)}...` : this.state.form.file.value.name) : 'Upload mp3'} onChange={this.onFileChange} required custom />
              {this.state.fileError ? <Alert severity="error">{this.state.fileError}</Alert> : null}
            </Form.Group>
            <Form.Control type="hidden" name="form-name" value="Submission_Page_Form" />
            <ReCAPTCHA
              ref={this.recaptchaRef}
              size="normal"
              sitekey={process.env.REACT_APP_SITE_RECAPTCHA_KEY}
              onErrored={() => {
                this.setState({ error: 'Could not send message! reCaptcha Failed' });
              }}
            />
            <Button variant="primary" type="submit" style={{ margin: '15px' }} disabled={this.state.loading}>
              {this.state.loading ? "Loading..." : "Submit"}
            </Button>
            <Col xs={12} hidden={!this.state.error} style={{ margin: '15px' }}>
              {(this.state.error) ? <Alert variant="danger">{this.state.error}</Alert> : null}
            </Col>
            <Col xs={12} hidden={!this.state.success} style={{ margin: '15px' }}>
              {(this.state.success)
                ? <Alert variant="success">{this.state.success}</Alert> : null}
            </Col>
          </Form>
        </Col>
        <Col md={3} />
      </Row>
    );
  }
}

export default Submissions;
