import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import Home from './components/Home';
import Navigation from './components/Navigation';
import Music from './components/Music';
import Roster from './components/Roster';
import Merch from './components/Merch';
import Submissions from './components/Submissions';
import config from './config.json';

function App() {
  const keywords = [];
  config.site_keywords.forEach((keyword) => {
    keywords.push(keyword.keyword);
  });
  const meta = {
    title: config.site_title,
    description: config.site_description,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: keywords.join(','),
      },
    },
  };

  return (
    <Router>
      <DocumentMeta {...meta}>
        <div className="App">
          <Navigation />
          <Switch style={{padding: '25px'}}>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/music">
              <Music />
            </Route>
            <Route path="/roster">
              <Roster />
            </Route>
            <Route path="/merch">
              <Merch />
            </Route>
            <Route path="/submission">
              <Submissions />
            </Route>
          </Switch>
        </div>
      </DocumentMeta>
    </Router>
  );
}

export default App;
