import React, { Component } from 'react';
import Row from 'react-bootstrap/cjs/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faFacebook, faInstagram, faSpotify, faLinkedin, faSoundcloud,
} from '@fortawesome/free-brands-svg-icons';
import { Container } from 'react-bootstrap';
import roster from '../roster.json';

class Roster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roster: [],
    };
  }

  isNull(string) {
    return string === undefined || string === '' || string === null;
  }

  componentDidMount() {
    const byKey = roster.slice(0);
    byKey.sort((a, b) => a.key - b.key);
    return this.setState({ roster: byKey });
  }

  render() {
    return (
      <div className="content">
        {this.state.roster.map((person) => {
          const socialIcons = [];
          if (!this.isNull(person.facebook)) {
            socialIcons.push(<a href={person.facebook}><FontAwesomeIcon icon={faFacebook} size="2x" /></a>);
          }
          if (!this.isNull(person.twitter)) {
            socialIcons.push(<a href={person.twitter}><FontAwesomeIcon icon={faTwitter} size="2x" /></a>);
          }
          if (!this.isNull(person.instagram)) {
            socialIcons.push(<a href={person.instagram}>
              <FontAwesomeIcon
                icon={faInstagram}
                size="2x"
              />
                             </a>);
          }
          if (!this.isNull(person.linkedin)) {
            socialIcons.push(<a href={person.linkedin}>
              <FontAwesomeIcon
                icon={faLinkedin}
                size="2x"
              />
                             </a>);
          }
          if (!this.isNull(person.soundcloud)) {
            socialIcons.push(<a href={person.soundcloud}>
              <FontAwesomeIcon
                icon={faSoundcloud}
                size="2x"
              />
                             </a>);
          }
          if (!this.isNull(person.spotify)) {
            socialIcons.push(<a href={person.spotify}>
              <FontAwesomeIcon
                icon={faSpotify}
                size="2x"
              />
                             </a>);
          }
          return (
            <Container>
              <Row key={person.key.toString()}>
                <Col sm={12} md={6} className="roster headshot-container">
                  <div className="roster headshot" style={{ backgroundImage: `url('${person.headshot}')` }} />
                </Col>
                <Col sm={12} md={6} lg={5} className="roster info">
                  <h2 className="name">{person.name}</h2>
                  <h6 className="title">{person.title}</h6>
                  <p className="bio">{person.bio}</p>
                  <div className="social-icons">
                    {socialIcons}
                  </div>
                </Col>
              </Row>
            </Container>
          );
        })}
      </div>
    );
  }
}

export default Roster;
